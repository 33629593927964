import React from 'react';
import { toAbsoluteUrl } from '../../../../helpers/AssetHelpers';

interface Props {
  overallStatistics: any;
}
export default function OverallDistribution({ overallStatistics }: Props) {
  return (
    <div className="dashboard-card card card-xl-stretch mb-5 mb-xl-8 rating-box-otr ">
      <div className="inner-header d-flex">
        <h4 className="title flex-1">Satisfaction Rating Box</h4>
        <span className="tol-revision">
          <b>Total Responses : </b>
          {overallStatistics?.totalCount}
        </span>
      </div>
      <div
        className="inner-body"
        //  style={{ minHeight: "90px" }}
      >
        <>
          <div className="row">
            <div className="col-lg-4">
              <div className="inner-card flex-column">
                <p className="title">{overallStatistics?.timeline[0]}</p>
                <p className="value current">
                  {overallStatistics?.currentAverageSatisfaction}%
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="inner-card flex-column">
                <p className="title">{overallStatistics?.timeline[1]}</p>
                <p className="value last">
                  {overallStatistics?.previousAverageSatisfaction}%
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="inner-card align-items-center">
                {overallStatistics?.improved ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    className="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    className="bi bi-arrow-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                  </svg>
                )}

                <p className="growth-value">{overallStatistics?.difference}%</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="rating-container">
                <div className="item-container" key={1}>
                  <div className="item">
                    <img
                      src={toAbsoluteUrl(
                        '/media/icons/satisfaction/very-bad.png'
                      )}
                      alt="Very Bad"
                    />
                    <div className="count-detail">
                      <p className="count">
                        {overallStatistics?.individualPercent[0].total}
                      </p>
                      <p className="percentage">
                        {overallStatistics?.individualPercent[0].percent}%
                      </p>
                    </div>
                  </div>
                </div>

                <div className="item-container" key={2}>
                  <div className="item">
                    <img
                      src={toAbsoluteUrl('/media/icons/satisfaction/bad.png')}
                      alt="Bad"
                    />
                    <div className="count-detail">
                      <p className="count">
                        {overallStatistics?.individualPercent[1].total}
                      </p>
                      <p className="percentage">
                        {overallStatistics?.individualPercent[1].percent}%
                      </p>
                    </div>
                  </div>
                </div>

                <div className="item-container" key={3}>
                  <div className="item">
                    <img
                      src={toAbsoluteUrl('/media/icons/satisfaction/good.png')}
                      alt="Medium"
                    />
                    <div className="count-detail">
                      <p className="count">
                        {overallStatistics?.individualPercent[2].total}
                      </p>
                      <p className="percentage">
                        {overallStatistics?.individualPercent[2].percent}%
                      </p>
                    </div>
                  </div>
                </div>

                <div className="item-container" key={4}>
                  <div className="item">
                    <img
                      src={toAbsoluteUrl(
                        '/media/icons/satisfaction/medium.png'
                      )}
                      alt="Good"
                    />
                    <div className="count-detail">
                      <p className="count">
                        {overallStatistics?.individualPercent[3].total}
                      </p>
                      <p className="percentage">
                        {overallStatistics?.individualPercent[3].percent}%
                      </p>
                    </div>
                  </div>
                </div>

                <div className="item-container" key={5}>
                  <div className="item">
                    <img
                      src={toAbsoluteUrl(
                        '/media/icons/satisfaction/very-good.png'
                      )}
                      alt="Very Good"
                    />
                    <div className="count-detail">
                      <p className="count">
                        {overallStatistics?.individualPercent[4].total}
                      </p>
                      <p className="percentage">
                        {overallStatistics?.individualPercent[4].percent}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
