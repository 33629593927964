import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { User } from '../store/core/models';
import { APICore } from '../apis/api.core';

const api = new APICore();
interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

interface FirebaseConfigEnv {
  [dev: string]: FirebaseConfig;
}

const firebaseConfig: FirebaseConfigEnv = {
  dev: {
    apiKey: 'AIzaSyDSgvCO4fn6siTLyie7mYN0bQe_SYB0u8E',
    authDomain: 'semadashboardexternaluat.firebaseapp.com',
    projectId: 'semadashboardexternaluat',
    storageBucket: 'semadashboardexternaluat.firebasestorage.app',
    messagingSenderId: '697404517845',
    appId: '1:697404517845:web:e620c21b80627b5824e15c',
    measurementId: 'G-PMJRTE5HWF',
  },
  stage: {
    apiKey: 'AIzaSyDjOznSkaBFS-_DihBV3bVrSMGp3relGKs',
    authDomain: 'semadashboard-2b85a.firebaseapp.com',
    projectId: 'semadashboard-2b85a',
    storageBucket: 'semadashboard-2b85a.appspot.com',
    messagingSenderId: '578092524148',
    appId: '1:578092524148:web:7c2ddb6c6f21dce889e721',
    measurementId: 'G-ZZ7TDLF76C',
  },
  production: {
    apiKey: 'AIzaSyBeyH0g918NsaH_bMtU8zwLcLAduSJ8m4U',
    authDomain: 'semadashboardexternalprod.firebaseapp.com',
    projectId: 'semadashboardexternalprod',
    storageBucket: 'semadashboardexternalprod.firebasestorage.app',
    messagingSenderId: '677516713865',
    appId: '1:677516713865:web:926bac27a905aaabce8b58',
    measurementId: 'G-0B6M6R4S4X',
  },
};
const env = process.env.REACT_APP_NODE_ENV;

const app = initializeApp(firebaseConfig[env || 'dev']);
const analytics = getAnalytics(app);

const setUserDetails = (
  userId: string,
  userName: string,
  userEmail: string
) => {
  // Set the user ID for analytics
  setUserId(analytics, userId);

  // Set custom user properties
  setUserProperties(analytics, {
    name: userName,
    email: userEmail,
  });
};

const logAnalyticsEvent = (
  eventName: string,
  eventParams: { [key: string]: string },
  user: User
) => {
  if (api.getUserKey('track_analytics') === 'on') {
    setUserDetails(user.id, user.firstName, user.email);

    logEvent(analytics, eventName, eventParams);
  }
};

export { logAnalyticsEvent };
