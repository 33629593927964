import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import {
  AUTH_LOCAL_KEY,
  AUTH_LOCAL_USER,
  AuthContextType,
  User,
} from './core/models';
import { APICore } from '../apis/api.core';
const api = new APICore();

// Create Context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a Provider Component
function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<string | null>(() =>
    localStorage.getItem(AUTH_LOCAL_KEY)
  );
  const [user, setUser] = useState<User | null>(() => {
    const userString = localStorage.getItem(AUTH_LOCAL_USER);
    if (userString) {
      try {
        return JSON.parse(userString) as User;
      } catch (error) {
        return null;
      }
    }
    return null;
  });

  useEffect(() => {
    if (token) {
      localStorage.setItem(AUTH_LOCAL_KEY, token);
      localStorage.setItem(AUTH_LOCAL_USER, JSON.stringify(user));
    } else {
      localStorage.removeItem(AUTH_LOCAL_KEY);
      localStorage.removeItem(AUTH_LOCAL_USER);
    }
  }, [token, user]);

  const login = (newToken: string, loggedInUser: User) => {
    setToken(newToken);
    setUser(loggedInUser);
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    api.removeUserKey('track_analytics');
  };

  return (
    <AuthContext.Provider value={{ token, user, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook for using the context
function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
