/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@tanstack/react-query';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import AppButton from '../sharedcomponents/Buttons/AppButton/AppButton';

import NotFound from '../sharedcomponents/NotFound/NotFound';
import KeyMetrics from './components/KeyMetrics/KeyMetrics';
import TextMetrics from './components/TextMetrics/TextMetrics';

import './core/style.scss';

import {
  getCardStatistics,
  getTextStatistics,
  getYesNoStatistics,
  getOldStatistics,
} from '../../apis/app.service';

import { useAuth } from '../../store/AuthProvider';
import ChartMetrics from './components/ChartMetrics/ChartMetrics';
import clsx from 'clsx';
import OldDisplay from './components/OldDisplay/OldDisplay';
import { logAnalyticsEvent } from '../../helpers/firebase';
const d = new Date();
const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
let monthIndex = d.getMonth();
const defaultMonth = monthArray[monthIndex];
let defaultYear = d.getFullYear();

export default function InterviewsPage() {
  const [month, setMonth] = useState<string>(defaultMonth);
  const [year, setYear] = useState<string>(`${defaultYear}`);
  const [department, setDepartment] = useState<string>(``);
  const [allQueryError, setAllQueryError] = useState<boolean>(false);
  const [allQueryLoading, setAllQueryLoading] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  const newStatsDate = new Date(2024, 10);
  const selectedDate = new Date(parseInt(year), monthArray.indexOf(month));

  useEffect(() => {
    if (user)
      logAnalyticsEvent(
        'sema_user_page_views',
        {
          interviews:
            user.institution?.name || `${user.firstName} ${user.lastName}`,
        },
        user
      );
  }, []);

  const YesNoQuery = useQuery({
    queryKey: ['Yes_NO Statistics'],
    queryFn: () => getYesNoStatistics({ month, year, department }),
  });

  const cardStatisticsQeury = useQuery({
    queryKey: ['Card Statistics'],
    queryFn: () => getCardStatistics({ month, year, department }),
  });

  const textStatisticsQuery = useQuery({
    queryKey: ['Text Statistics'],
    queryFn: () => getTextStatistics({ month, year, department }),
  });

  const OldStatisticsQuery = useQuery({
    queryKey: ['Old Statistics'],
    queryFn: () => getOldStatistics({ month, year }),
  });

  useEffect(() => {
    if (user?.department.length) {
      setDepartment(user?.department[0]);
    }
  }, []);

  useEffect(() => {
    if (selectedDate >= newStatsDate) {
      YesNoQuery.refetch();
      cardStatisticsQeury.refetch();
      textStatisticsQuery.refetch();
    } else {
      OldStatisticsQuery.refetch();
    }
  }, [month, year, department]);

  useEffect(() => {
    if (
      cardStatisticsQeury.isError &&
      YesNoQuery.isError &&
      textStatisticsQuery.isError
    ) {
      setAllQueryError(true);
    } else {
      setAllQueryError(false);
    }
  }, [
    cardStatisticsQeury.isError,
    YesNoQuery.isError,
    textStatisticsQuery.isError,
  ]);

  useEffect(() => {
    if (
      (cardStatisticsQeury.isFetching && YesNoQuery.isFetching,
      textStatisticsQuery.isFetching)
    ) {
      setAllQueryLoading(true);
    } else {
      setAllQueryLoading(false);
    }
  }, [
    cardStatisticsQeury.isFetching,
    YesNoQuery.isFetching,
    textStatisticsQuery.isFetching,
  ]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'page',
    onAfterPrint: () => handlePdfDownload(),
  });

  const handlePdfDownload = async () => {
    if (componentRef.current) {
      const canvas = await html2canvas(componentRef.current, { scale: 1 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      // pdf.save('page.pdf');
    }
  };

  return (
    <div ref={componentRef} className="interviews">
      <div className="p-2 card shadow-sm">
        <div className="d-flex justify-content-between">
          <form className="form" noValidate id="devices_data_filter">
            <div className="w-100 d-flex flex-row justify-content-end">
              <div className="d-flex">
                <select
                  className="form-select form-select-solid me-4"
                  name="month"
                  value={department}
                  disabled={allQueryLoading || selectedDate < newStatsDate}
                  onChange={(e) => {
                    setDepartment(e.target.value);
                  }}
                >
                  {user?.department?.map((department, index) => (
                    <option key={index} value={department}>
                      {department}
                    </option>
                  ))}
                </select>

                <select
                  className="form-select form-select-solid me-4"
                  name="month"
                  value={month}
                  disabled={allQueryLoading}
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                >
                  <option key={0} value="January">
                    January
                  </option>

                  <option key={1} value="February">
                    February
                  </option>
                  <option key={2} value="March">
                    March
                  </option>

                  <option key={3} value="April">
                    April
                  </option>

                  <option key={4} value="May">
                    May
                  </option>

                  <option key={5} value="June">
                    June
                  </option>

                  <option key={6} value="July">
                    July
                  </option>

                  <option key={7} value="August">
                    August
                  </option>

                  <option key={8} value="September">
                    September
                  </option>

                  <option key={9} value="October">
                    October
                  </option>

                  <option key={10} value="November">
                    November
                  </option>

                  <option key={11} value="December">
                    December
                  </option>
                </select>

                <select
                  className="form-select form-select-solid"
                  name={year}
                  value={year}
                  disabled={allQueryLoading}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                >
                  <option key={0} value="2026">
                    2026
                  </option>
                  <option key={1} value="2025">
                    2025
                  </option>

                  <option key={2} value="2024">
                    2024
                  </option>

                  <option key={3} value="2023">
                    2023
                  </option>

                  <option key={4} value="2022">
                    2022
                  </option>

                  <option key={5} value="2022">
                    2022
                  </option>
                </select>
              </div>
            </div>
          </form>
          <span className="print-button">
            <AppButton
              text="Export as PDF"
              btnType="info"
              width="180px"
              onClick={handlePrint}
            />
          </span>
        </div>
      </div>

      <div
        className={clsx('py-4 d-flex flex-column', {
          'gap-4': !allQueryError || allQueryLoading,
        })}
      >
        {selectedDate >= newStatsDate && (
          <>
            <KeyMetrics cardStatisticsQeury={cardStatisticsQeury} />
            <ChartMetrics YesNoQuery={YesNoQuery} />
            <TextMetrics textStatisticsQuery={textStatisticsQuery} />
            {allQueryError && !allQueryLoading && <NotFound />}
          </>
        )}

        {selectedDate < newStatsDate && (
          <>
            <OldDisplay
              OldStatisticsQuery={OldStatisticsQuery}
              month={monthArray.indexOf(month)}
              year={year}
            />
            {OldStatisticsQuery.isError && !OldStatisticsQuery.isFetching && (
              <NotFound />
            )}
          </>
        )}
      </div>
    </div>
  );
}
