/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { getFilters, getDeviceStats } from '../../apis/app.service';
import { useAuth } from '../../store/AuthProvider';
import { DeviceDatePicker } from './components/datepicker/DeviceDatePicker';
import LoadingSpinner from '../sharedcomponents/LoadingSpinner';
import { PercentageDistButtonPresses } from './components/PercentageDistButtonPresses/PercentageDistButtonPresses';
import OverallDistribution from './components/OverallDistribution/OverallDistribution';

import './core/style.scss';
import DeviceComparison from './components/DeviceComparison/DeviceComparison';
import TargetSatisfaction from './components/TargetSatisfaction/TargetSatisfaction';
import { logAnalyticsEvent } from '../../helpers/firebase';

export default function DevicePage() {
  const { user } = useAuth();
  const [filter, setFilter] = useState<
    { placement?: string; user_Id: string | string[] } | undefined
  >(undefined);
  const [type, setType] = useState<string>('week');
  const [selectedDateFormatted, setSelectedDateFormatted] = useState(
    dayjs().format('YYYY-Wo')
  );
  const [selectedDate, setSelectedDate] = useState<any>(
    dayjs(dayjs(), 'YYYY-Wo')
  );

  const [dateQuery, setDateQuery] = useState({
    currentStartDate: dayjs().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
    currentEndDate: dayjs().endOf('week').format('YYYY-MM-DD HH:mm:ss'),
    previousStartDate: dayjs()
      .startOf('week')
      .subtract(1, 'weeks')
      .format('YYYY-MM-DD HH:mm:ss'),
    previousEndDate: dayjs()
      .endOf('week')
      .subtract(1, 'weeks')
      .format('YYYY-MM-DD HH:mm:ss'),
    timeline: ['This week', 'Last week'],
  });

  const {
    data: filters,
    isFetching,
    isSuccess,
  } = useQuery({
    queryKey: ['Device Filters'],
    queryFn: getFilters,
  });

  useEffect(() => {
    if (user)
      logAnalyticsEvent(
        'sema_user_page_views',
        {
          devices:
            user.institution?.name || `${user.firstName} ${user.lastName}`,
        },
        user
      );
  }, []);

  const DeviceStatsQuery = useQuery({
    queryKey: ['Device statistics'],
    queryFn: () =>
      getDeviceStats({
        filter,
        type,
        ...dateQuery,
      }),
    // The query will not execute until the filters exists
    enabled: !!filter,
  });

  useEffect(() => {
    if (isSuccess && filters?.data?.length > 0) {
      let { value } = filters.data[0];

      if (user!.role === 'institution_head') {
        if (value === 'All') {
          setFilter({ user_Id: [user!.id] });
        } else {
          setFilter({
            user_Id: [user!.id],
            placement: value,
          });
        }
      }

      if (user!.role === 'institution_global_head') {
        setFilter({ user_Id: value });
      }
    }
  }, [isSuccess, filters?.data, user]);

  useEffect(() => {
    DeviceStatsQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, selectedDate, filter?.placement, filter?.user_Id]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="d-flex align-items-end ps-8">
        <div className="card w-100 py-1">
          <div className="w-100 d-flex ">
            <div className=" me-3">
              <select
                className="form-select form-select-solid"
                name="filter"
                disabled={DeviceStatsQuery.isFetching}
                value={filter?.placement || filter?.user_Id}
                onChange={(e) => {
                  let { value } = e.target;
                  if (user!.role === 'institution_head') {
                    if (value === 'All') {
                      setFilter({ user_Id: [user!.id] });
                    } else {
                      setFilter({
                        user_Id: [user!.id],
                        placement: value,
                      });
                    }
                  }

                  if (user!.role === 'institution_global_head') {
                    setFilter({ user_Id: value.split(',') });
                  }
                }}
              >
                {filters?.data?.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <DeviceDatePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedDateFormatted={selectedDateFormatted}
              setSelectedDateFormatted={setSelectedDateFormatted}
              type={type}
              setType={setType}
              isFetching={DeviceStatsQuery.isFetching}
              setDateQuery={setDateQuery}
            />
          </div>
        </div>
      </div>
      <div>
        {!DeviceStatsQuery.isFetching ? (
          <div className="p-4">
            <OverallDistribution
              overallStatistics={
                DeviceStatsQuery?.data?.data?.overallStatistics
              }
            />

            <PercentageDistButtonPresses
              className="card-xl-stretch mb-5 mb-xl-8"
              title={'Percentage distribution of button presses'}
              categories={
                DeviceStatsQuery?.data?.data?.timeDistribution?.categories || []
              }
              series={
                DeviceStatsQuery?.data?.data?.timeDistribution?.series || []
              }
            />

            <div className="row">
              <div className="col-xl-6 d-flex align-items-stretch">
                <DeviceComparison
                  className="w-100"
                  data={
                    DeviceStatsQuery?.data?.data?.overallDeviceComparisons || []
                  }
                />
              </div>
              <div className="col-xl-6 d-flex align-items-stretch">
                <TargetSatisfaction
                  className="w-100"
                  data={
                    DeviceStatsQuery?.data?.data?.targetDeviceSatisfaction || []
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
}
