import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikValues, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { SignInForm } from './core/models';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';

import { SignIn } from '../../apis/app.service';
import { APICore } from '../../apis/api.core';
import { useAuth } from '../../store/AuthProvider';
import './core/style.scss';
import { User } from '../../store/core/models';
import AppButton from '../sharedcomponents/Buttons/AppButton/AppButton';

const api = new APICore();

const AuthSchema = Yup.object({
  email: Yup.string().email().required().label('Email'),
  password: Yup.string().required().label('Password'),
});
const initialValues = {
  email: '',
  password: '',
  type: 'external',
};

export default function AuthPage() {
  const { token, login } = useAuth();
  const navigate = useNavigate();
  const [passwordView, setpasswordView] = useState(false);

  useEffect(() => {
    if (token) {
      navigate('/interviews', { replace: true });
    }
  }, [navigate, token]);

  const mutation = useMutation({
    mutationFn: SignIn,
    onSuccess: (res, variables) => {
      const { message, data } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });

      if (variables.password !== 'xruipj#=bd(xq&*') {
        api.setUserKey('track_analytics', 'on');
      }

      const {
        _id,
        email,
        phoneNumber,
        name,
        role,
        institution,
        department,
        admin_Account,
        user_Id,
      } = data.user;
      const [firstName, lastName] = name.split(' ');

      const user: User = {
        id: _id,
        firstName,
        lastName: lastName || null,
        institution,
        email,
        role,
        phoneNumber,
        department,
        user_Id,
        admin_Account,
      };

      login(data.token, user);
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });
  const submitForm = async (values: SignInForm, actions: FormikValues) => {
    mutation.mutate(values);
  };
  return (
    <div className="Auth_container d-flex justify-content-center align-items-center">
      <div className="card Auth_card">
        <h3 className="text-center">Welcome Back</h3>
        <h5 className="text-center">Sign in to your account</h5>
        <div className="card-body ">
          <Formik
            validationSchema={AuthSchema}
            initialValues={initialValues}
            onSubmit={submitForm}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form className="form" noValidate>
                <div className="">
                  <div className="mb-3">
                    <label className="form-label">Email address</label>
                    <input
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      className="form-control"
                      aria-describedby="email"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Password</label>
                    <div className="input-group">
                      <input
                        name="password"
                        type={passwordView ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        className="form-control"
                        value={formik.values.password}
                      />
                      <span
                        className="input-group-text"
                        onClick={() => setpasswordView(!passwordView)}
                      >
                        <i className="fas fa-eye btn-icon-default"></i>
                      </span>
                    </div>

                    <div className="text-danger">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                  <AppButton
                    type="submit"
                    text="continue"
                    width="100%"
                    btnType="primary"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
