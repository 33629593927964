import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export const AUTH_LOCAL_KEY = 'talktosema_user';
// content type

interface CustomResponse {
  status: string;
  message: string;
  data: any; // Can pass a generic type here depending on which api endpoint is being called.
}
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

instance.interceptors.request.use((request) => {
  const token = getUserFromCookie();
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});
// intercepting to capture errors
instance.interceptors.response.use(
  (response): AxiosResponse<CustomResponse> => {
    const res = {
      status: response.data.status,
      message: response.data.message,
      data: response.data ? response.data.data : null,
    };
    const axiosResponse: AxiosResponse<CustomResponse> = {
      ...response,
      data: res,
    };
    return axiosResponse;
  },
  (error): { data: CustomResponse } => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    // Happens when axios fails due to cors errors or axios fails to reach server due to internet problems
    if (error.message === 'Network Error' || !error.response) {
      message = navigator.onLine
        ? 'Server offline! Please contact your service provider'
        : 'Network Error! Please check your internet connection';
      return {
        data: {
          status: 'fail',
          message,
          data: null,
        },
      };
    }

    switch (error.response.status) {
      case 400:
        message = 'Bad Request';
        break;
      case 401:
        message = 'Invalid credentials! Please log in again';
        break;
      case 403:
        message = 'Access Forbidden';
        break;
      case 404:
        message = 'Sorry! the data you are looking for could not be found';
        break;
      default: {
        message =
          error.response && error.response.data
            ? error.response.data['message']
            : error.message || error;
      }
    }
    if (error.response.status === 401) {
      localStorage.removeItem(AUTH_LOCAL_KEY);
      window.location.reload();
    }
    return {
      data: {
        status: error.response.data.status,
        message: error.response.data.message
          ? error.response.data.message
          : message,
        data: error.response ? error.response : null,
      },
    };
  }
);

const getUserFromCookie = () => {
  const token = localStorage.getItem(AUTH_LOCAL_KEY);

  return token ? token : null;
};

class APICore {
  /**
   * Fetches data from given url
   */
  getAPI = (
    url: string,
    params?: AxiosRequestConfig
  ): Promise<AxiosResponse<CustomResponse>> => {
    return instance.get(`${url}`, params);
  };

  /**
   * post given data to url
   */
  postAPI = (
    url: string,
    data: unknown,
    config = {}
  ): Promise<AxiosResponse<CustomResponse>> => {
    return instance.post(url, data, config);
  };
  /**
   * Updates patch data
   */
  patchAPI = (
    url: string,
    data: unknown,
    config = {}
  ): Promise<AxiosResponse<CustomResponse>> => {
    return instance.patch(url, data, config);
  };

  /**
   * Updates data
   */
  putAPI = (
    url: string,
    data: unknown
  ): Promise<AxiosResponse<CustomResponse>> => {
    return instance.put(url, data);
  };

  /**
   * Deletes data
   */
  deleteAPI = (
    url: string,
    params?: AxiosRequestConfig
  ): Promise<AxiosResponse<CustomResponse>> => {
    return instance.delete(url, params);
  };

  isUserAuthenticated = () => {
    // 2 Get logged in user for checking if routes are protected
    return !!getUserFromCookie();
  };

  updateLoggedInUserProfileData = (newUser: any) => {
    const update = { user: newUser };
    localStorage.setItem(AUTH_LOCAL_KEY, JSON.stringify(update));
  };

  setLoggedInUser = (session: any) => {
    if (session)
      sessionStorage.setItem(AUTH_LOCAL_KEY, JSON.stringify(session));
    else {
      sessionStorage.removeItem(AUTH_LOCAL_KEY);
    }
  };

  setUserKey = (key: string, value: string) => {
    localStorage.setItem(`${AUTH_LOCAL_KEY}_${key}`, value);
  };

  getUserKey = (key: string) => {
    return localStorage.getItem(`${AUTH_LOCAL_KEY}_${key}`);
  };

  removeUserKey = (key: string) => {
    localStorage.removeItem(`${AUTH_LOCAL_KEY}_${key}`);
  };

  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };
}

export { APICore };
